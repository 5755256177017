import React from "react";
import { fontSize } from "../../../constants/styled";
import { routes } from "../../../constants/routings";
import CustomButton from "../../shared/CustomButton/CustomButton";
import moment from "moment";
import RejectButton from "./RejectButton";
import { actionTypeMap } from "../../../constants/constants";
import translationIcon from "../../../resources/translationIcon.png";
import newsIcon from "../../../resources/newsIcon.png";

const CardActions = ({ eventData, isActionConfirm, actionType, currentPath, language, isClusters, isRelatedPublished, republishDisabled, handleNonConfirmationAction = () => { }, handleConfirmBasedAction = () => { }, handleConfirmAction = () => { }, setShowRelatedPublished = () => { } }) => {
    return <>
        <div className="xl:flex space-y-4 xl:space-y-0 justify-between xl:items-center" style={{ marginTop: "2rem" }}>
            <div className="">
                <div className="flex space-x-2">
                    <div className="flex-none">
                        <div className="flex px-2 py-1 bg-[#EDE9F3] rounded-[4px] space-x-1 items-center">
                            <img src={translationIcon} alt="translation Icon" className="h-[0.8rem]" />
                            <p className={`${fontSize[10]} text-[#71608D] whitespace-nowrap`}>AI Translated from {language}</p>
                        </div>
                    </div>
                    <div className="flex-none">
                        <div className="flex px-2 py-1 bg-[#E1F2E2] rounded-[4px] space-x-1 items-center">
                            <img src={newsIcon} alt="news Icon" className="h-[0.8rem]" />
                            <p className={`${fontSize[10]} text-[#468548] whitespace-nowrap`}>{moment(currentPath === routes.rejected.key ? eventData?.reject_date : currentPath === routes.healthInfo.key ? eventData?.saved_healthinfo_date : currentPath === routes.shortlisted.key ? eventData?.shortlist_date : currentPath === routes.published.key ? eventData.published_date : eventData?.extracted_date).format('h:mma [on] Do MMMM')} {currentPath === routes.healthInfo.key ? "saved" : currentPath} by {currentPath === routes.extracted.key ? "Model" : "User"}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex space-x-4 justify-end">
                {!isActionConfirm && (currentPath === routes.extracted.key || currentPath === routes.shortlisted.key) && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        icon={{ pre: true, class: `pi-briefcase ${fontSize[14]} text-gray font-[500]` }}
                        text={{ text: "Save as Health Info", class: "text-gray font-[500]" }}
                        background="px-2 xl:px-3 py-1 lg:py-2 whitespace-nowrap bg-white"
                        border="rounded-[8px] border-1 border-gray"
                        hover="hover:cursor-pointer"
                        action={() => handleNonConfirmationAction(actionTypeMap.saveAsHealthInfo.key)}
                    />
                </div>}
                {!isActionConfirm && !isClusters && [routes.extracted.key, routes.shortlisted.key].includes(currentPath) && <div className="flex-none">
                    <RejectButton handleAction={handleConfirmBasedAction} />
                </div>}
                {!isActionConfirm && isClusters && [routes.extracted.key].includes(currentPath) && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        text={{ text: actionTypeMap.remove.name, class: "font-[500]" }}
                        background="px-2 md:px-3 xl:px-4 py-1 lg:py-2 border-1 border-[#d7686d] text-[#d7686d] hover:bg-[#d7686d] hover:text-white"
                        border="rounded-[8px]"
                        hover="hover:cursor-pointer"
                        action={() => handleNonConfirmationAction(actionTypeMap.remove.key)}
                    />
                </div>}
                {!isActionConfirm && currentPath === routes.extracted.key && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        text={{ text: "Shortlist", class: "text-white font-[500]" }}
                        background="px-2 md:px-3 xl:px-4 py-1 lg:py-2 whitespace-nowrap bg-green"
                        border="rounded-[8px]"
                        hover="hover:cursor-pointer"
                        action={() => handleNonConfirmationAction(actionTypeMap.shortlist.key)}
                    />
                </div>}
                {!isActionConfirm && currentPath === routes.shortlisted.key && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        text={{ text: "Publish", class: "text-white font-[500]" }}
                        background="px-2 md:px-3 xl:px-4 py-1 lg:py-2 whitespace-nowrap bg-green"
                        border="rounded-[8px]"
                        hover="hover:cursor-pointer"
                        action={() => handleConfirmBasedAction(actionTypeMap.publish.key)}
                    />
                </div>}
                {!isActionConfirm && currentPath === routes.published.key && !eventData.parent_id && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        text={{ text: "Re-publish", class: "text-white font-[500]" }}
                        background="px-2 md:px-3 xl:px-4 py-1 lg:py-2 whitespace-nowrap bg-green"
                        border="rounded-[8px]"
                        hover="hover:cursor-pointer"
                        action={() => handleConfirmBasedAction(actionTypeMap.republish.key)}
                    />
                </div>}
                {!isActionConfirm && currentPath === routes.published.key && eventData?.parent_id && !isRelatedPublished && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        text={{ text: "View related events", class: "text-[#78828C] font-[500]" }}
                        background="px-2 md:px-3 xl:px-4 py-1 lg:py-2 whitespace-nowrap"
                        border="border-1 border-[#78828C] rounded-[8px]"
                        hover="hover:cursor-pointer"
                        action={() => setShowRelatedPublished(true)}
                    />
                </div>}
                {isActionConfirm && <div className="flex-none">
                    <CustomButton
                        fontSize={fontSize[14]}
                        icon={{ pre: actionType === actionTypeMap.reject.key && true, class: `pi-trash ${fontSize[14]} text-white font-[500]` }}
                        text={{ text: `${actionType === actionTypeMap.reject.key ? "Confirm Reject" : actionType === actionTypeMap.publish.key ? "Confirm Publish" : actionType === actionTypeMap.republish.key ? "Re-publish" : ""}`, class: "text-white font-[500]" }}
                        background={`px-2 lg:px-4 py-1 lg:py-2 ${actionType === "reject" ? "bg-[#EC4646]" : "bg-green"}`}
                        border="rounded-[8px]"
                        hover={`${republishDisabled ? "hover:cursor-not-allowed" : "hover:cursor-pointer"}`}
                        action={() => {
                            if (republishDisabled) {
                                return null;
                            }
                            handleConfirmAction(actionType);
                        }}
                    />
                </div>}
            </div>
        </div>
    </>
}

export default CardActions;
