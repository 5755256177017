import React from "react";
import { fontSize } from "../../../constants/styled";
import { actionTypeMap } from "../../../constants/constants";

export const ClusterCount = ({ count, conClick = () => { } }) => {
    return <>
        <div className="bg-blue-light px-2 h-[100%] flex justify-center items-center hover:cursor-pointer" >
            <div className="text-[#3C7DAE] text-[2rem] md:text-[2.1rem] lg:text-[2.5rem] xl:text-[3rem] font-[700] opacity-[50%]">
                +{count}
            </div>
        </div>
    </>
}

export const PublishedSummary = ({ eventStatus, setShowDialog = () => { } }) => {
    const mappings = {
        Pending: {
            text: "See Pending Action Status",
            color: "text-green",
            bg: "bg-[#e8f4f0]",
            iconColor: "text-[#e8f4f0]",
            iconBg: "bg-green"
        },
        Ongoing: {
            text: "See Ongoing Surveillance Status",
            color: "text-[#74082a]",
            bg: "bg-[#e0d1d6]",
            iconColor: "text-[#e0d1d6]",
            iconBg: "bg-[#74082a]"
        },
        Closed: {
            text: "Closed Alert",
            color: "text-[#414c5b]",
            bg: "bg-[#d9d9d9]",
            iconColor: "text-[#d9d9d9]",
            iconBg: "bg-[#414c5b]"
        },
        Completed: {
            text: "See Completed Outbreak Details",
            color: "text-[#413f40]",
            bg: "bg-[#f8ecc6]",
            iconColor: "text-[#f8ecc6]",
            iconBg: "bg-[#413f40]"
        }
    }
    return <>
        <div className={`${mappings[eventStatus].bg} flex items-center py-2 px-3 space-x-2 justify-end hover:cursor-pointer`} onClick={() => setShowDialog(true)} >
            <p className={`${mappings[eventStatus].color} font-[700] ${fontSize[16]}`}>{mappings[eventStatus].text}</p>
            <i className={`pi pi-plus rounded-[50%] ${mappings[eventStatus].iconColor} ${mappings[eventStatus].iconBg} text-white font-[700] p-1 ${fontSize[12]}`} />
        </div>
    </>
}

const notificationStyle = {
    reject: "bg-[#FDD6D6] text-[#EC4646]",
    remove: "bg-[#FDD6D6] text-[#EC4646]",
    shortlist: "bg-[#CFF3E4] text-[#16754C]",
    publish: "bg-[#CFF3E4] text-[#16754C]",
    republish: "bg-[#CFF3E4] text-[#16754C]",
    saveAsHealthInfo: "bg-[#CFF3E4] text-[#16754C]",
}

export const ActionSuccessNotification = ({ actionType, diseaseName, isClusters, handleUndoAction = () => { }, handleCloseNotification = () => { }, performActionCall = () => { } }) => {
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            performActionCall(actionType)
        }, 1500)
        return () => {
            clearTimeout(timeout)
        }
    }, [actionType, performActionCall])
    return <>
        <div className={`${isClusters ? "w-full mb-3" : "md:w-5/6 lg:w-11/12 xl:w-23/24"}`}>
            <div className={`flex items-center py-3 px-3 space-x-4 justify-between ${notificationStyle[actionType]}`} >
                <div className="flex">
                    {/* <i className={`pi pi-plus border-1 rounded-[50%] bg-green text-white font-[700] p-1 ${fontSize[10]}`} /> */}
                    <p className={`font-[500] ${fontSize[16]}`}>You have {actionTypeMap[actionType].name} the {diseaseName}'s event</p>
                </div>
                <div className="flex space-x-6">
                    <p className={`text-[#413F40] font-[500] ${fontSize[14]} hover:cursor-pointer`} onClick={handleUndoAction}>Undo</p>
                    <i className={`pi pi-times ${actionType === "reject" ? "bg-[#FFC5C5]" : "bg-[#BAE9D5]"} p-2 rounded-[50%] font-[500] p-1 ${fontSize[12]} hover:cursor-pointer`} onClick={handleCloseNotification} />
                </div>
            </div>
        </div>
    </>
}
