import { diseaseCategoryApi, stateCategoryApi, districtCategoryApi, subdistrictCategoryApi, languageCategoriApi } from "../apis/index";
import { filterActions } from "./filterSlices";
import fetchApi, { getHeaders } from "../utils/fetchApi";

export const getFilterData = (authToken) => {
    return async (dispatch) => {
        dispatch(getSubdistricts(authToken))
        dispatch(getLanguages(authToken))
        const selectedDiseases = await dispatch(getDiseases(authToken));
        const selectedStates = await dispatch(getStates(authToken));
        dispatch(getDistricts(authToken, selectedStates))
        dispatch(filterActions.setIsAllFetched(selectedDiseases && selectedStates))
    };
};

export const getDiseases = (authToken) => {
    const headerValue = getHeaders(authToken)
    return async (dispatch) => {
        const response = await fetchApi(diseaseCategoryApi, "GET", {}, headerValue, {});
        dispatch(filterActions.setFilters({
            disease: response?.data?.diseases || [],
        }))
        const selectedDiseases = await response?.data?.diseases?.flatMap(disease => disease?.items).map(disease => disease?.value).filter(dis => dis !== "COVID-19");
        dispatch(filterActions.setSelectedFilters({
            disease: selectedDiseases || [],
        }))
        return selectedDiseases;
    }
}

export const getStates = (authToken) => {
    const headerValue = getHeaders(authToken)
    return async (dispatch) => {
        const response = await fetchApi(stateCategoryApi, "GET", {}, headerValue, {});
        dispatch(filterActions.setFilters({
            state: response?.data?.states || [],
        }))
        const selectedStates = response?.data?.states?.map(st => st?.value);
        dispatch(filterActions.setSelectedFilters({
            state: ["", ...selectedStates] || [],
        }))
        return selectedStates
    }
}

export const getDistricts = (authToken, selectedStates) => {
    const headerValue = getHeaders(authToken);
    return async (dispatch) => {
        const response = await fetchApi(districtCategoryApi, "GET", {}, headerValue, {});
        // Updating because all other apis has {labe: "", items: []}
        const updatedDistricts = await response?.data?.districts?.map(obj => {
            return { label: obj?.name, items: obj?.items };
        });
        const districtCategoryData = await updatedDistricts.filter(category =>
            selectedStates.some(state => category.label === state)
        );
        const selectedDistricts = districtCategoryData.flatMap(state => state.items).map(dist => dist.value)
        dispatch(filterActions.setFilters({
            district: districtCategoryData || []
        }))
        dispatch(filterActions.setSelectedFilters({
            district: ["", ...selectedDistricts] || []
        }))
    }
}

export const getSubdistricts = (authToken) => {
    const headerValue = getHeaders(authToken);
    return async (dispatch) => {
        const response = await fetchApi(subdistrictCategoryApi, "GET", {}, headerValue, {});
        dispatch(filterActions.setFilters({
            subDistrict: response?.data?.subdistricts || undefined,
        }))
    }
}

export const getLanguages = (authToken) => {
    const headerValue = getHeaders(authToken);
    return async (dispatch) => {
        const response = await fetchApi(languageCategoriApi, "GET", {}, headerValue, {});
        const langs = response.data.languages.reduce((acc, lang) => {
            acc[lang.value] = lang.name;
            return acc;
        }, {});
        dispatch(filterActions.setFilters({
            language: langs,
        }))
    }
}
