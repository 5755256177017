import React from "react";
import { fontSize } from "../../constants/styled";
import { useDispatch } from "react-redux";
import { filterActions } from "../../store/filterSlices";
import { routes } from "../../constants/routings";
import useReduxSearch from "../../hooks/useReduxSearch";
import { getEventCards, getEventCount } from "../../store/eventActions";

const PublishSubTypes = ({ currentPath }) => {
    const dispatch = useDispatch()
    const selectedFilters = useReduxSearch(routes.selectedFilters.key)
    const [subTypes, setSubTypes] = React.useState({ pending: selectedFilters.subTypes.includes("pending"), ongoing: selectedFilters.subTypes.includes("ongoing"), closed: selectedFilters.subTypes.includes("closed"), completed: selectedFilters.subTypes.includes("completed") })
    const authToken = useReduxSearch(routes.auth.key).authToken
    const currentPage = useReduxSearch(routes.pagination.key)[routes.published.key].currentPage
    const params = {
        eventType: selectedFilters.eventType,
        fromDate: selectedFilters.fromDate,
        toDate: selectedFilters.toDate,
        disease: selectedFilters.disease,
        state: selectedFilters.state,
        district: selectedFilters.district,
        currentPage,
    }
    const Item = ({ flag, text }) => {
        return <>
            <div className={`border-[0.5px] rounded-[2px] border-gray w-[12px] h-[12px] ${flag ? "bg-[#115C81] border-blue" : "bg-white"} flex justify-center items-center`}>
                <i className={`pi ${flag ? "pi-check" : ""} text-[0.7rem] font-[700] text-white`}></i>
            </div>
            <p className={`${fontSize[14]} text-[#413F40] font-[500] group-hover:text-[#008BCA]`}>{text}</p>
        </>
    }
    const handleSelect = (key) => {
        setSubTypes(prevState => ({ ...prevState, [key]: !prevState[key] }))
        const selectedSubTypes = Object.entries({ ...subTypes, [key]: !subTypes[key] })
            .filter(([key, value]) => value === true)
            .map(([key]) => key)
        dispatch(filterActions.setSelectedFilters({
            subTypes: selectedSubTypes
        }))
        params["subTypes"] = selectedSubTypes
        dispatch(getEventCount(params, authToken, "filter"))
        dispatch(getEventCards(params, authToken))
    }
    React.useEffect(() => {
        return () => {
            if (currentPath === routes.published.key) {
                dispatch(getEventCount(params, authToken, "filter"))
            }
            dispatch(filterActions.setSelectedFilters({
                subTypes: []
            }))
        }
    }, [])
    return <>
       <div className="flex hover:cursor-pointer">
            <div className={`flex space-x-2 px-3 py-[1px] hover:bg-[#CCE6F2] items-center border-1 group border-[#9BAFB8] rounded-s-[15px] ${subTypes.pending && "bg-[#A3D6EE]"}`} onClick={() => handleSelect("pending")}>
                <Item flag={subTypes.pending} text="Pending Action"/>
            </div>
            <div className={`flex space-x-2 px-3 py-[1px] hover:bg-[#CCE6F2] items-center border-top-1 group border-bottom-1 border-[#9BAFB8] ${subTypes.closed && "bg-[#A3D6EE]"}`} onClick={() => handleSelect("closed")}>
                <Item flag={subTypes.closed} text="Closed Alert"/>
            </div>
            <div className={`flex space-x-2 px-3 py-[1px] hover:bg-[#CCE6F2] items-center border-1 group border-[#9BAFB8] ${subTypes.ongoing && "bg-[#A3D6EE]"}`} onClick={() => handleSelect("ongoing")}>
                <Item flag={subTypes.ongoing} text="Ongoing Surveillance"/>
            </div>
            <div className={`flex space-x-2 px-3 py-[1px] hover:bg-[#CCE6F2] items-center border-1 group border-left-none border-[#9BAFB8] rounded-e-[15px] ${subTypes.completed && "bg-[#A3D6EE]"}`} onClick={() => handleSelect("completed")}>
                <Item flag={subTypes.completed} text="Completed Outbreak"/>
            </div>
        </div>
    </>
}

export default PublishSubTypes;
