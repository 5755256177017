import React from "react";
import CustomDialogBox from "../../shared/CustomDialogBox/CustomDialogBox";
import { fontSize } from "../../../constants/styled";
import CardTitle from "../../Card/Components/CardTitle";
import CardFileds from "../../Card/Components/CardFields";
import CardActions from "../../Card/Components/CardActions";
import { actionTypeMap } from "../../../constants/constants";
import rejectIcon from "../../../resources/rejectIcon.png";
import publishIcon from "../../../resources/publishedIcon.png";

const ConfirmationCard = ({ idx, currentPath, eventData, parentEvent, totalEvents, filterData, currentPage, isActionConfirm, actionType, setEventData = () => { }, handleConfirmAction = () => { } }) => {
    const [validation, setValidation] = React.useState({ initial: true, final: false })
    const handleDiseaseChange = (event, label) => {
        setEventData((prev) => ({
            ...prev,
            disease: event,
            disease_macro_category: label
        }))
        handleRepublishValidation()
    }
    const handleStateChange = (event) => {
        const newSelectedDistrict = Object.keys(filterData.subDistrict[event])[0]
        setEventData((prev) => ({
            ...prev,
            state: event,
            district: newSelectedDistrict,
            sub_district: ""
        }))
        handleRepublishValidation()
    }
    const handleDistrictChange = (event) => {
        setEventData((prev) => ({
            ...prev,
            district: event,
            sub_district: ""
        }))
        handleRepublishValidation()
    }
    const handleSubdistrictChange = (event) => {
        setEventData((prev) => ({
            ...prev,
            sub_district: event
        }))
        handleRepublishValidation()
    }
    const handleNumberChange = (event) => {
        setEventData((prev) => ({
            ...prev,
            number: {
            ...prev.number,
            ...event
            }
        }))
    }
    console.log(actionType)
    const handleRepublishValidation = () => {
        if (actionType === actionTypeMap.republish.key) {
            setValidation(prev => ({ ...prev, final: true }))
        }
    }
    return <div className={`flex mb-3 w-full shadow-[0px_1px_5px_0px_#74072A61]`}>
        <div className={`flex-grow flex-shrink-0 basis-0`}>
            <div className="bg-white py-2 lg:pt-3 lg:pb-2">
                <CardTitle idx={idx} eventData={eventData} showCollapse={false} disabled={true} currentPath={currentPath} totalEvents={totalEvents} currentPage={currentPage} />
                <div className="ml-2 border-t-2 border-blue-light"></div>
                <div className="w-full">
                    <div className="px-3 md:px-4 xl:px-5">
                        <div className="space-y-1 md:space-y-2 lg:space-y-3 xl:space-y-4 my-2 lg:my-3 xl:my-4">
                            <CardFileds
                                eventData={eventData}
                                currentPath={currentPath}
                                isActionConfirm={isActionConfirm}
                                filterData={filterData}
                                handleDiseaseChange={handleDiseaseChange}
                                handleNumberChange={handleNumberChange}
                                handleStateChange={handleStateChange}
                                handleDistrictChange={handleDistrictChange}
                                handleSubdistrictChange={handleSubdistrictChange}
                            />
                            <CardActions
                                eventData={eventData}
                                isActionConfirm={isActionConfirm}
                                republishDisabled={actionType === actionTypeMap.republish.key && validation.initial !== validation.final}
                                actionType={actionType}
                                currentPath={currentPath}
                                language={filterData?.language[eventData?.language]}
                                handleConfirmAction={handleConfirmAction}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const ActionConfirmation = ({ idx, showDialog, eventData, filterData, currentPage, currentPath, totalEvents, actionType, setShowDialog = () => { }, setActionSuccessNotification = () => { } }) => {
    const [event, setEvent] = React.useState(eventData)
    const handleConfirmAction = (type) => {
        setActionSuccessNotification({ show: true, type: actionType })
        setShowDialog((prev) => ({ ...prev, show: false, type: null, data: event }))
    }
    return <>
        <CustomDialogBox
            showDialog={showDialog}
            classes="w-4/6"
            clickOutsideClose={false}
            header={{
                img: actionType === actionTypeMap.reject.key ? rejectIcon : actionType === actionTypeMap.publish.key ? publishIcon : actionType === actionTypeMap.republish.key ? publishIcon : null,
                text: actionType === actionTypeMap.republish.key ? "Edit Fields to Re-publish" : `Confirm ${actionType}ing this event?`,
                classes: `bg-[#D8E9EF] text-[#413F40] font-[600] ${fontSize[24]} py-2 pl-6 space-x-4 rounded-4`,
            }}
            setShowDialog={() => setShowDialog((prev) => ({ ...prev, show: false, type: null }))}
        >
            <div className="px-4 py-2 md:py-3 lg:px-5 xl:px-6 mb-8">
                <ConfirmationCard idx={idx} currentPath={currentPath} filterData={filterData} eventData={event} parentEvent={eventData} setEventData={setEvent} totalEvents={totalEvents} currentPage={currentPage} isActionConfirm={true} actionType={actionType} showCollapse={false} handleConfirmAction={handleConfirmAction} />
            </div>
        </CustomDialogBox>
    </>
}

export default ActionConfirmation;
