import React from "react";
import "./CardComponents.css";
import { fontSize } from "../../../constants/styled";
import CustomGroupSelect from "../../shared/CustomGroupSelect/CustomGroupSelect";
import CustomSelect from "../../shared/CustomSelect/CustomSelect";
import diseaseIcon from "../../../resources/diseaseIcon.png";
import locationIcon from "../../../resources/locationIcon.png";
import { InputNumber } from 'primereact/inputnumber';
import caseNumberIcion from "../../../resources/caseNumberIcon.png";
import deathNumberIcion from "../../../resources/deathNumberIcon.png";
import editNumberIcion from "../../../resources/editNumberIcon.png";
import expandMessageIcon from "../../../resources/expandMessageIcon.png";
import { routes } from "../../../constants/routings";
import CustomTooltip from "../../shared/CustomTooltip/CustomTooltip";

export const subDistrictProvider = (subDistircts, state, district) => {
    if (state && district) {
        const stateSubdistricts = subDistircts[state];
        if (stateSubdistricts && district in stateSubdistricts) {
            return stateSubdistricts[district];
        }
    }
    return [];
}

export const generateArticleSummary = (article) => {
    return article.title + " " + article.description;
}

export const transformArray = (originalArray) => {
    if (!originalArray.length) return [];
    const transformedArray = originalArray.map(item => ({ name: item, value: item })).sort((a, b) => a.value.localeCompare(b.value));
    return transformedArray;
}

const disabledField = (currentPath, isActionConfirm) => {
    return (currentPath === routes.published.key && !isActionConfirm) || (currentPath !== routes.published.key && isActionConfirm) || currentPath === routes.rejected.key || currentPath === routes.healthInfo.key
}

const CardFileds = ({ eventData, currentPath, filterData, isActionConfirm, validation, disabled, handleDiseaseChange = () => { }, handleNumberChange = () => { }, handleStateChange = () => { }, handleDistrictChange = () => { }, handleSubdistrictChange = () => { } }) => {
    const [showOriginalArticle, setShowOriginalArticle] = React.useState(false)
    const [expandTextbox, setExpandTextbox] = React.useState(false)
    const [numberCases, setNumberCases] = React.useState({
        caseKey: eventData.number.case_new > 0 ? "new" : eventData.number.case_total > 0 ? "total" : "new",
        deathKey: eventData.number.death_new > 0 ? "new" : eventData.number.death_total > 0 ? "total" : "new",
        case: {
          caseKey: eventData.number.case_new > 0 ? eventData.number.case_new : eventData.number.case_total > 0 ? eventData.number.case_total : 0,
        },
        death: {
          deathKey: eventData.number.death_new > 0 ? eventData.number.death_new : eventData.number.death_total > 0 ? eventData.number.death_total : 0,
        }
    });
    function handleCardNumberChange (e, type) {
        const number = e.value
        const numberObj = {};

        if (type === "case") {
          setNumberCases((prevCases) => ({
            ...prevCases,
            case: {
              caseKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.caseKey] = number
          handleNumberChange(numberObj)
        } else {
          setNumberCases((prevCases) => ({
            ...prevCases,
            death: {
              deathKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.deathKey] = number
          handleNumberChange(numberObj)
        }
    }
    return <>
        <div className="">
            <div className="flex space-x-8 lg:space-x-12">
                <div className="w-[50%] md:w-[40%] lg:w-[30%]">
                    <div className={`${fontSize[12]} font-[700] pb-1`}>Health Condition</div>
                    <div className="w-full">
                        <CustomGroupSelect options={filterData?.disease} selectedOption={eventData?.disease} handleChange={handleDiseaseChange} icon={diseaseIcon} filter={true} disabled={disabled || disabledField(currentPath, isActionConfirm)} classes={`w-full bg-[#E8F6FF] border-1 ${!validation?.disease ? "border-[#B2CDE2]" : "border-[#fa0a36]"} rounded-[10px]`} panelClasses={`w-full pb-2 lg:pb-3 border-1 border-[#B2CDE2] ${fontSize[12]}`} />
                    </div>
                </div>
                <div className="w-[40%] md:w-[30%] lg:w-[25%]">
                    <div className={`${fontSize[12]} font-[700] pb-1`}>Reported Death & Cases</div>
                    <div className="flex input-text-box-wrapper">
                        <div className={`w-1/2 ${fontSize[12]} left flex space-x-3 items-center`}>
                            <img src={deathNumberIcion} alt="death number icon" className="h-[1.8vw] lg:h-[1.3vw] xl:h-[1vw]" />
                            <InputNumber inputId="death" value={numberCases.death.deathKey} disabled={disabled || disabledField(currentPath, isActionConfirm)} onValueChange={(e) => handleCardNumberChange(e, 'death')} min={0} />
                        </div>
                        <div className={`w-1/2 ${fontSize[12]} right flex space-x-3 items-center`}>
                            <img src={caseNumberIcion} alt="case number icon" className="h-[1.8vw] lg:h-[1.3vw] xl:h-[1vw] pl-3" />
                            <InputNumber inputId="case" value={numberCases.case.caseKey} disabled={disabled || disabledField(currentPath, isActionConfirm)} onValueChange={(e) => handleCardNumberChange(e, 'case')} min={0} />
                            <CustomTooltip text="Edit Deaths/Cases">
                                <img src={editNumberIcion} alt="edi number icon" className="h-[1.8vw] lg:h-[1.3vw] xl:h-[1vw] edit-number-tooltip" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-[2px] space-x-1">
            <p className={`${fontSize[10]} text-gray`}>AI extracted condition: {eventData?.ml_extracted_disease}</p>
            </div>
        </div>
        <div className="">
            <div className="flex">
                <div className="w-[32%] md:w-[30%] lg:w-[25%]">
                    <div className={`${fontSize[12]} font-[700] pb-1`}>Location: State<span className="text-[#EC4646]">*</span></div>
                    <div className="w-full">
                        <CustomSelect selectedOption={eventData.state} placeholder="Select State" options={transformArray(Object.keys(filterData.subDistrict))} handleChange={handleStateChange} icon={locationIcon} filter={true} disabled={disabled || disabledField(currentPath, isActionConfirm)} classes={`w-full bg-[#E8F6FF] border-1 ${!validation?.state ? "border-[#B2CDE2]" : "border-[#fa0a36]"} rounded-e-none rounded-s-[8px] ${fontSize[12]}`} panelClasses={`w-full pb-2 lg:pb-3 border-1 border-[#B2CDE2] ${fontSize[12]}`} />
                    </div>
                </div>
                <div className="w-[32%] md:w-[30%] lg:w-[25%]">
                    <div className={`${fontSize[12]} font-[700] pb-1`}>District<span className="text-[#EC4646]">*</span></div>
                    <div className="w-full">
                        <CustomSelect selectedOption={eventData.district} placeholder="Select District" options={eventData?.state ? transformArray(Object.keys(filterData.subDistrict[eventData?.state]), eventData?.state) : []} handleChange={handleDistrictChange} filter={true} disabled={disabled || disabledField(currentPath, isActionConfirm)} classes={`w-full bg-[#E8F6FF] ${!validation?.district ? "border-y-1 border-[#B2CDE2]" : "border-1 border-[#fa0a36]"} rounded-none ${fontSize[12]}`} panelClasses={`w-full pb-2 lg:pb-3 border-1 border-[#B2CDE2] ${fontSize[12]}`} />
                    </div>
                </div>
                <div className="w-[32%] md:w-[30%] lg:w-[25%]">
                    <div className={`${fontSize[12]} font-[700] pb-1`}>Sub-District / ULB</div>
                    <div className="w-full">
                        <CustomGroupSelect options={subDistrictProvider(filterData?.subDistrict, eventData?.state, eventData?.district)} selectedOption={eventData?.sub_district} placeholder="Select Sub District / ULB" handleChange={handleSubdistrictChange} showClear={true} filter={true} disabled={disabled || disabledField(currentPath, isActionConfirm)} classes={`w-full bg-[#E8F6FF] border-1 border-[#B2CDE2] rounded-s-none rounded-e-[10px] ${fontSize[12]}`} panelClasses={`w-full pb-2 lg:pb-3 border-1 border-[#B2CDE2] ${fontSize[12]}`} />
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-[2px] space-x-1">
                <p className={`${fontSize[10]} text-gray`}>AI extracted location: {eventData.location}</p>
            </div>
        </div>
        <div className="w-[100%]">
            <div className="flex justify-between pb-1">
                <p className={`${fontSize[12]} font-[700]`}>{showOriginalArticle ? "Original Summary" : "Translated Summary"}</p>
                <p className={`${fontSize[12]} text-[#0F52FF] hover:cursor-pointer`} onClick={() => setShowOriginalArticle(!showOriginalArticle)}>{showOriginalArticle ? "View Translated Summary" : "View Original Summary"}</p>
            </div>
            <div className="w-[100%] relative top-0 left-0 px-3 py-2 border-1 border-[#D0DEE8] rounded-[15px]">
                <div className={`w-[100%] text-text-color ${!expandTextbox ? "max-h-[3.4rem] overflow-hidden" : ""} ${fontSize[12]}`}>
                    {showOriginalArticle ? generateArticleSummary(eventData?.article) : generateArticleSummary(eventData?.translated_article)}
                </div>
                <img src={expandMessageIcon} alt="message-expand-icon" className="h-[1.2rem] absolute right-1 bottom-1 hover:cursor-pointer" onClick={() => setExpandTextbox(!expandTextbox)}/>
            </div>
        </div>
    </>
}

export default CardFileds;
