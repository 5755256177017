import { createSlice } from "@reduxjs/toolkit";
import { routes } from "../constants/routings";
import { getToday, getYesterDay } from "../utils/methods";

const initialState = {
    filters: {
        disease: [],
        state: [],
        district: [],
        subDistrict: undefined,
        language: []
    },
    selectedFilters: {
        eventType: "extracted",
        subTypes: [],
        fromDate: getYesterDay(),
        toDate: getToday(),
        disease: [],
        state: [],
        district: [],
    },
    isFetchedRequiredData: false
}

const filterSlice = createSlice({
    name: routes.filters.reduxKey,
    initialState,
    reducers: {
        setFilters (state, actions) {
            state.filters.disease = actions.payload.disease || state.filters.disease;
            state.filters.state = actions.payload.state || state.filters.state;
            state.filters.district = actions.payload.district || state.filters.district;
            state.filters.subDistrict = actions.payload.subDistrict || state.filters.subDistrict;
            state.filters.language = actions.payload.language || state.filters.language;
        },
        setSelectedFilters (state, actions) {
            state.selectedFilters.fromDate = actions.payload.fromDate || state.selectedFilters.fromDate;
            state.selectedFilters.toDate = actions.payload.toDate || state.selectedFilters.toDate;
            state.selectedFilters.disease = actions.payload.disease || state.selectedFilters.disease;
            state.selectedFilters.state = actions.payload.state || state.selectedFilters.state;
            state.selectedFilters.district = actions.payload.district || state.selectedFilters.district;
            state.selectedFilters.eventType = actions.payload.eventType || state.selectedFilters.eventType;
            state.selectedFilters.subTypes = actions.payload.subTypes || state.selectedFilters.subTypes;
        },
        setIsAllFetched (state, action) {
            state.isFetchedRequiredData = action.payload;
        },
    }
});

export const filterActions = filterSlice.actions;

export default filterSlice.reducer;
