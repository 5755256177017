import React from "react";
import Filters from "../components/Filters/Filter";
import { headerHeight } from "../constants/styled";
import CustomPaginator from "../components/shared/CustomPaginator/CustomPaginator";
import PublishSubTypes from "../components/PublishSubTypes/PublishSubTypes";
import { routes } from "../constants/routings";
import Updates from "../components/Updates/Updates";
import SourceBlock from "../components/SourceBlock/SourceBlock";

const Header = ({ currentPath }) => {
    return (
        <div className={`flex flex-col justify-between ${headerHeight} pt-3`}>
            <div className="w-[100%] h-[1.5rem] md:h-[2rem] lg:h-[2.4rem] xl:h-[2.6rem] flex justify-between">
                <div className="w-[70%] h-[100%] md:w-[73%] lg:w-[75%] xl:w-[77%] 2xl:w-[70%]">
                    <Filters />
                </div>
                <div className="w-[25%] h-[100%] md:w-[24%] lg:w-[20%] xl:w-[15%] 2xl:w-[12%] flex flex-col justify-between">
                    <div className={`w-[100%] h-[100%] flex items-center space-x-3 justify-end text-text-color font-[500]`}>
                        <div className="h-[100%]">
                            <Updates />
                        </div>
                        {/* <div className="hidden md:block md:h-[100%]">
                            <SourceBlock isShowList={true} currentPath={currentPath} />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="w-[100%] lg:pt-2 pb-1 lg:pb-1 pl-4 flex justify-between items-center">
                <div>
                    {currentPath === routes.published.key && <PublishSubTypes currentPath={currentPath} />}
                </div>
                <div className="font-[500]">
                    <CustomPaginator currentPath={currentPath}/>
                </div>
            </div>
            <div></div>
        </div>
    )
};

export default Header;
