import { itemsPerPage } from "../constants/constants"

export const getToday = () => new Date()

export const getYesterDay = () => new Date(new Date().setDate(new Date().getDate() - 1))

export const getCurrentAndTotalPage = (count) => {
    const currentPage = count > 0 ? 1 : 0;
    const totalPage = Math.ceil((count / itemsPerPage) || 0)
    return { currentPage, totalPage }
}
