import React from "react";
import "./Card.css";
import { routes } from "../../constants/routings";
import { ActionSuccessNotification, ClusterCount, PublishedSummary } from "./Components/Components";
import useReduxSearch from "../../hooks/useReduxSearch";
import { useDispatch } from "react-redux";
import { postActionButtonClick } from "../../store/eventActions";
import CardTitle from "./Components/CardTitle";
import CardActions from "./Components/CardActions";
import RelatedCards from "../Dialogs/RelatedCards/RelatedCards";
import ActionConfirmation from "../Dialogs/ActionConfirmation/ActionConfirmation";
import CardFileds from "./Components/CardFields";
import { actionTypeMap } from "../../constants/constants";
import { publishEvent, rejectEvent, removeEvent, republishEvent, saveAsHealthInfo, shortlistEvent } from "../../utils/apiCalls";
import SummaryDialog from "../Dialogs/SummaryDialog/SummaryDialog";
import { notificationActions } from "../../store/notificationSlices";

const Card = ({ idx, currentPath, eventCard, totalEvents, currentPage, mainPage, isClusters, isRelatedPublished, actionType, showCollapse }) => {
  const dispatch = useDispatch()
  const filterData = useReduxSearch(routes.filters.key);
  const selectedFilterData = useReduxSearch(routes.selectedFilters.key);
  const authentication = useReduxSearch(routes.auth.key);
  const [collapseCard, setCollapseCard] = React.useState(false);
  const [eventData, setEventData] = React.useState(eventCard);
  const [showClusters, setShowClusters] = React.useState(false);
  const [showRelatedPublished, setShowRelatedPublished] = React.useState(false)
  const [actionConfirmaction, setActionConfirmation] = React.useState({ show: false, type: null, option: null, data: null })
  const [actionSuccessNotification, setActionSuccessNotification] = React.useState({ show: false, type: null, data: null });
  const [showSummaryDialog, setShowSummaryDialog] = React.useState(false)
  const [validation, setValidation] = React.useState({ disease: false, state: false, district: false })
  const cardRef = React.useRef(null);
  const [cardDimensions, setCardDimensions] = React.useState({ width: 0, height: 0 });
  const apiRequestParamsFilter = {
    eventType: currentPath,
    fromDate: selectedFilterData.fromDate,
    toDate: selectedFilterData.toDate,
    disease: selectedFilterData.disease,
    state: selectedFilterData.state,
    district: selectedFilterData.district,
    currentPage: mainPage,
  }
  if (selectedFilterData.subTypes && selectedFilterData.subTypes.length) {
    apiRequestParamsFilter["subTypes"] = selectedFilterData.subTypes
  }
  const apiRequestPayload1 = {
    event_id: eventData.id,
    cluster_id: eventData.cluster_id,
    disease: eventData.disease,
    disease_macro_category: eventData.disease_macro_category,
    state: eventData.state,
    district: eventData.district,
    sub_district: eventData.sub_district,
    number: eventData.number,
    url: eventData.url,
    date: eventData.extracted_date
  }
  const handleDiseaseChange = (event, label) => {
    setEventData((prev) => ({
      ...prev,
      disease: event,
      disease_macro_category: label
    }))
    if (validation.disease) { setValidation(prev => ({ ...prev, disease: false })) }
  }
  const handleStateChange = (event) => {
    const newSelectedDistrict = Object.keys(filterData.subDistrict[event])[0]
    setEventData((prev) => ({
      ...prev,
      state: event,
      district: newSelectedDistrict,
      sub_district: ""
    }))
    if (validation.state) { setValidation(prev => ({ ...prev, state: false })) }
  }
  const handleDistrictChange = (event) => {
    setEventData((prev) => ({
      ...prev,
      district: event,
      sub_district: ""
    }))
    if (validation.district) { setValidation(prev => ({ ...prev, district: false })) }
  }
  const handleSubdistrictChange = (event) => {
    setEventData((prev) => ({
      ...prev,
      sub_district: event
    }))
  }
  const handleNumberChange = (event) => {
    setEventData((prev) => ({
      ...prev,
      number: {
        ...prev.number,
        ...event
      }
    }))
  }
  const handleNonConfirmationAction = (type) => {
    const valid = [actionTypeMap.saveAsHealthInfo.key].includes(type) || handleFieldValidation()
    if (!valid) return
    setActionSuccessNotification({ show: true, type })
    console.log("action taken: ", type)
  }
  const handleConfirmBasedAction = (type, option) => {
    console.log(type)
    const valid = [actionTypeMap.reject.key].includes(type) || handleFieldValidation()
    if (!valid) return
    console.log("action taken: ", type, ", ", option)
    setActionConfirmation((prev) => ({ ...prev, show: true, type, option }))
  }
  const handleConfirmAction = (type) => {
    setActionConfirmation((prev) => ({ ...prev, show: false, type: null }))
  }
  const handleFieldValidation = () => {
    if (!eventData.disease || !eventData.state || !eventData.district) {
      setValidation({ disease: !eventData.disease, state: !eventData.state, district: !eventData.district })
      return false
    }
    return true
  }
  const showActionErrorNotification = (message) => {
    dispatch(notificationActions.resetNotification());
    setTimeout(() => {
        dispatch(notificationActions.setNoification({
            open: true,
            message
        }));
        setActionSuccessNotification({ show: false, type: null, data: null })
    }, 100);
  }
  const performActionCall = (actionType) => {
    switch (actionType) {
      case actionTypeMap.shortlist.key:
        apiRequestPayload1["date"] = eventData?.extracted_date
        shortlistEvent(apiRequestPayload1, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath, routes.shortlisted.key], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to Shortlist Event")
          }
        })
        break;
      case actionTypeMap.reject.key:
        console.log(actionConfirmaction)
        apiRequestPayload1["reject_type"] = actionConfirmaction.option.name
        rejectEvent(apiRequestPayload1, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath, routes.rejected.key], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to Reject Event")
          }
        })
        break;
      case actionTypeMap.remove.key:
        removeEvent(apiRequestPayload1, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to De-cluster Event")
          }
        })
        break;
      case actionTypeMap.saveAsHealthInfo.key:
        saveAsHealthInfo(apiRequestPayload1, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath, routes.healthInfo.key], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to Save Health Info")
          }
        })
        break;
      case actionTypeMap.publish.key:
        apiRequestPayload1["ihip_token"] = authentication.ihipToken
        apiRequestPayload1["date"] = eventData?.extracted_date
        publishEvent(apiRequestPayload1, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath, routes.published.key], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to Publish Event")
          }
        })
        break;
      case actionTypeMap.republish.key:
        republishEvent({
          ...apiRequestPayload1,
          disease: actionConfirmaction.data.disease,
          disease_macro_category: actionConfirmaction.data.disease_macro_category,
          state: actionConfirmaction.data.state,
          district: actionConfirmaction.data.district,
          sub_district: actionConfirmaction.data.sub_district,
          number: actionConfirmaction.data.number,
          message: eventData.article.title + " " + eventData.article.description,
          url: eventData.url,
          date: eventData.extracted_date,
          action_type: actionTypeMap.republish.value,
          ihip_token: authentication.ihipToken
        }, authentication.authToken).then(res => {
          if (res?.success) {
            dispatch(postActionButtonClick(apiRequestParamsFilter, [currentPath], authentication.authToken))
            setActionSuccessNotification({ show: false, type: null })
          } else {
            showActionErrorNotification(res?.detail ? res?.detail : "Failed to Re-publish Event")
          }
        })
        break;
      default:
    }
  }
  const handleUndoAction = () => {
    setActionSuccessNotification({ show: false, type: null })
  }
  const handleCloseNotification = () => {
    // performActionCall(actionSuccessNotification.type)
    // setActionSuccessNotification({ show: false, type: null })
  }
  React.useEffect(() => {
    const handleResize = () => {
      if (cardRef.current && !isClusters && eventData?.parent_id) {
        const { offsetWidth, offsetHeight } = cardRef.current;
        setCardDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [collapseCard]);
  if (actionSuccessNotification.show) {
    return <ActionSuccessNotification actionType={actionSuccessNotification.type} diseaseName={eventData.disease} isClusters={isClusters} handleUndoAction={handleUndoAction} handleCloseNotification={handleCloseNotification} performActionCall={performActionCall} />
  }
  return <>
    <div className={`relative flex ${eventData?.parent_id && !isClusters ? "mb-6" : "mb-4"} ${eventData?.cluster_count > 1 && "shadow-lg"} ${(isClusters) ? "w-full shadow-[0px_1px_5px_0px_#74072A61]" : "w-full md:w-5/6 lg:w-11/12 xl:w-23/24 event-card-container"}`}>
      <div ref={cardRef} className={`flex-grow flex-shrink-0 basis-0 border-1 border-[#F0F4F7] ${eventData?.parent_id && !isClusters && "border-[3px] border-[#008bca] shadow-[3px_3px_6px_1px_#008BCAB0]"}`}>
        <div className={`bg-white py-2 lg:pt-3 lg:pb-2  ${eventData?.cluster_count === 1 && "shadow-lg"}`}>
          <CardTitle idx={idx} eventData={eventData} collapseCard={collapseCard} showCollapse={showCollapse} currentPath={currentPath} totalEvents={totalEvents} currentPage={currentPage} authToken={authentication.authToken} setCollapseCard={setCollapseCard} />
          {!collapseCard && <div className="ml-2 border-t-2 border-blue-light"></div>}
          {!collapseCard && (
            <div className="w-full">
              <div className="px-3 md:px-4 xl:px-5">
                <div className="space-y-1 md:space-y-2 lg:space-y-3 xl:space-y-4 my-2 lg:my-3 xl:my-4">
                  <CardFileds
                    eventData={eventData}
                    currentPath={currentPath}
                    filterData={filterData}
                    validation={validation}
                    handleDiseaseChange={handleDiseaseChange}
                    handleNumberChange={handleNumberChange}
                    handleStateChange={handleStateChange}
                    handleDistrictChange={handleDistrictChange}
                    handleSubdistrictChange={handleSubdistrictChange}
                  />
                  <CardActions
                    eventData={eventData}
                    actionType={actionType}
                    currentPath={currentPath}
                    isClusters={isClusters}
                    isRelatedPublished={isRelatedPublished}
                    language={filterData?.language[eventData?.language]}
                    handleNonConfirmationAction={handleNonConfirmationAction}
                    handleConfirmBasedAction={handleConfirmBasedAction}
                    handleConfirmAction={handleConfirmAction}
                    setShowRelatedPublished={setShowRelatedPublished}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {currentPath === routes.published.key && !isClusters && <PublishedSummary eventStatus={eventData.outbreak_status} setShowDialog={setShowSummaryDialog}/>}
      </div>
      {eventData?.parent_id && !isClusters && <div className={`bg-white shadow-[1px_2px_4px_0px_#008BCAB0] absolute top-6 left-6 z-[-1]`} style={{ width: `${cardDimensions.width}px`, height: `${cardDimensions.height}px` }}/>}
      {!isClusters && <div className="flex-grow-0 flex-shrink-0 basis-1/6 md:basis-1/8 lg:basis-1/12 xl:basis-1/24 2xl:basis-1/5" onClick={() => { if (eventData.cluster_count > 1) { setShowClusters(true) } }}>
        {eventData.cluster_count > 1 && <ClusterCount count={eventData.cluster_count - 1} />}
      </div>}
      {showClusters && <RelatedCards relatedType="cluster" currentPath={currentPath} showClusters={showClusters} eventData={eventData} filterData={selectedFilterData} mainPage={mainPage} authToken={authentication.authToken} setShowClusters={setShowClusters} />}
      {showRelatedPublished && <RelatedCards relatedType="published" currentPath={currentPath} showClusters={showRelatedPublished} eventData={eventData} filterData={selectedFilterData} mainPage={mainPage} authToken={authentication.authToken} setShowClusters={setShowRelatedPublished} />}
      {actionConfirmaction.show && <ActionConfirmation idx={idx} showDialog={actionConfirmaction.show} currentPath={currentPath} actionType={actionConfirmaction.type} filterData={filterData} eventData={eventData} currentPage={currentPage} totalEvents={totalEvents} setShowDialog={setActionConfirmation} setActionSuccessNotification={setActionSuccessNotification} />}
      {showSummaryDialog && <SummaryDialog showDialog={showSummaryDialog} eventId={eventData?.id} eventStatus={eventData.outbreak_status} setShowDialog={setShowSummaryDialog} />}
    </div>
  </>
};

export default Card;
